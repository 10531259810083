import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { spacing } from '@hultafors/eripiowear/helpers';

const PlugsGrid = dynamic(() =>
  import('../plugs-grid/plugs-grid').then((module) => module.PlugsGrid),
);

export const Title = styled.p`
  max-width: 1440px;
  width: calc(100vw - 40px);
  margin-inline: auto;
  margin-block: 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:empty {
    display: none;
  }
`;

export const StyledPlugsGrid = styled(PlugsGrid)`
  ${Title} +  & {
    margin-block-start: ${spacing.small};
  }
`;

export const Container = styled.section`
  margin-block: ${spacing.medium};
`;
