import { useId } from 'react';

import { minWidth } from '@hultafors/shared/style';

import {
  ContentPlugsBlockFragment,
  PageTeaserFragment,
} from '@hultafors/eripiowear/types';

import { GenericPlug } from '../generic-plug/generic-plug';

import {
  Container,
  StyledPlugsGrid,
  Title,
} from './content-plugs-block.styled';

export type ContentPlugsBlockProps = ContentPlugsBlockFragment;

export const ContentPlugsBlock: React.FC<ContentPlugsBlockProps> = ({
  list,
  title,
  gridSize,
}) => {
  const uid = useId();

  const plugsMapper = (item: PageTeaserFragment) => {
    const key = `ContentPlugsBlock-${uid}-${item.id}`;
    const sizes = [
      '100vw',
      minWidth.largeMobile`50vw`,
      minWidth.desktopMedium`25vw`,
    ].join(', ');

    if (!item.slug) {
      return null;
    }

    const url = `/${item.slug}`.replace(/\/\//g, '/');

    let image = item.teaserImage;
    if (gridSize) {
      image = item.desktopTeaserImage ?? item.desktopImage;
    }

    return (
      <GenericPlug
        key={key}
        title={item.shortTitle}
        intro={item.teaserText}
        url={url}
        image={image}
        sizes={sizes}
      />
    );
  };
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <StyledPlugsGrid gridSize={gridSize}>
        {list?.map(plugsMapper)}
      </StyledPlugsGrid>
    </Container>
  );
};
